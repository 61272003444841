import { Atom, atom, PrimitiveAtom, WritableAtom } from 'jotai/index';
import { ColDef } from 'ag-grid-enterprise';
import { lapDeltaTableColDefs } from './LapDeltaTableColumns';
import { atomFamily } from 'jotai/utils';
import { LapDeltaData, OriginType, SanctioningBodyEnum } from 'models/viewModels';
import { activeSanctioningBodyFeedAtom, activeSanctioningBodyIdAtom } from 'jotai/sanctioningBody';
import _ from 'lodash';
import { LapDeltaDataObject, LapDeltaDataStreamInputProps } from './LapDeltaObject';
import { lapDeltaDataServiceCollection } from 'services/stream/RSocket/LapDeltaDataServiceRSocket';

let listenerId: string;

export const lapDeltaSelectorFamily = atomFamily<OriginType, Atom<LapDeltaDataObject[]>>((dataSource: OriginType) =>
  atom<LapDeltaDataObject[]>((get) => {
    const activeSanctioningBodyFeed = get(activeSanctioningBodyFeedAtom);
    const activeSanctioningBodyId = get(activeSanctioningBodyIdAtom);
    const sanctioningBodyEnum = activeSanctioningBodyId ?? SanctioningBodyEnum.UNKNOWN;
    const lapDeltaData = get(
      lapDeltaDataAtomFamily({
        dataSource: activeSanctioningBodyFeed ? activeSanctioningBodyFeed : dataSource,
        sanctioningBody: sanctioningBodyEnum,
      })
    );
    const referenceVehicle = get(referenceVehicleNumberSelector);
    const targetVehicle = get(targetVehicleNumberSelector);
    const filteredLapDeltaData = lapDeltaData.filter((obj) => obj.vehicleNumber === referenceVehicle);

    return filteredLapDeltaData.flatMap((obj) => {
      const currentDeltaMap = obj.deltaMetadata.currentDeltaMap;
      const targetDeltaMap = targetVehicle ? obj.deltaMetadata.vehicleBestLapDeltaMap[targetVehicle] : {};
      let cumulativeTotal = 0;

      return Object.entries(currentDeltaMap).map(([location, value]) => {
        const targetValue = targetDeltaMap?.[location] ?? 0;
        const delta = targetValue === 0 ? 0 : parseFloat((value - targetValue).toFixed(3));
        cumulativeTotal = parseFloat((cumulativeTotal + delta).toFixed(3));

        return {
          location: location,
          driver: value,
          target: targetValue,
          delta: delta,
          total: cumulativeTotal,
        } as LapDeltaDataObject;
      });
    });
  })
);

const currentLapDeltaDataAtomFamily = atomFamily<LapDeltaDataStreamInputProps, PrimitiveAtom<LapDeltaData[]>>(
  (input: LapDeltaDataStreamInputProps) => atom<LapDeltaData[]>([]),
  (a, b) => _.isEqual(a, b)
);

const initLapDeltaDataAtomFamily = atomFamily<LapDeltaDataStreamInputProps, WritableAtom<null, [], () => void>>(
  (input: LapDeltaDataStreamInputProps) => {
    const newAtom = atom<null, [], () => void>(null, (_get, set) => {
      const { dataSource, sanctioningBody } = input;
      listenerId = lapDeltaDataServiceCollection
        .get(sanctioningBody)!
        .get(dataSource)!
        .addListener(
          async (lapDeltaData) => {
            set(currentLapDeltaDataAtomFamily(input), lapDeltaData as LapDeltaData[]);
          },
          async () => {
            set(currentLapDeltaDataAtomFamily(input), [] as LapDeltaData[]);
          }
        );
      return () => {
        lapDeltaDataServiceCollection.get(sanctioningBody)?.get(dataSource)?.removeListener(listenerId);
      };
    });
    newAtom.onMount = (init) => {
      init();
    };
    return newAtom;
  },
  (a, b) => _.isEqual(a, b)
);

export const lapDeltaDataAtomFamily = atomFamily<LapDeltaDataStreamInputProps, Atom<LapDeltaData[]>>(
  (input: LapDeltaDataStreamInputProps) =>
    atom<LapDeltaData[]>((get) => {
      get(initLapDeltaDataAtomFamily(input));
      return get(currentLapDeltaDataAtomFamily(input));
    }),
  (a, b) => _.isEqual(a, b)
);

export const referenceVehicleNumberSelector = atom<string | undefined>(undefined);
export const targetVehicleNumberSelector = atom<string | undefined>(undefined);

export const lapDeltaTableColumnsSelector = atom<ColDef[]>((get) => {
  return lapDeltaTableColDefs;
});
