import { ManufacturerPointsData, OriginType, SanctioningBodyEnum } from 'models/viewModels';
import axiosConfig, { ApiRequestType } from 'axiosConfig/axiosConfig';
import { StreamRequestType } from './RSocketObject';
import { RSocketStreamBaseService } from './RSocketStreamBaseService';

export class ManufacturerPointsService extends RSocketStreamBaseService<
  ManufacturerPointsData[],
  ManufacturerPointsData
> {
  protected async getStreamHistoryRequest(): Promise<ManufacturerPointsData[]> {
    return axiosConfig(ApiRequestType.StreamMetricsApi)
      .post('/rest/backfill/manufacturerPoints', this.request)
      .then((response) => response.data as ManufacturerPointsData[])
      .catch(() => []);
  }

  protected onDataCallback = async (response: ManufacturerPointsData) => {
    if (this.listeners.size > 0) {
      this.localCache = [...this.localCache.filter((item) => item.manufacturer !== response.manufacturer), response];
    }
  };

  async start() {
    this.delay = 5000;
    super.start();
  }
}

export const manufacturerPointsRSocketService: Map<
  SanctioningBodyEnum,
  Map<OriginType, ManufacturerPointsService>
> = new Map(
  (Object.values(SanctioningBodyEnum) as SanctioningBodyEnum[])
    .filter((x) => typeof x === 'number')
    .map((sanctioningBody) => [
      sanctioningBody,
      new Map(
        (Object.values(OriginType) as OriginType[])
          .filter((x) => typeof x === 'number')
          .map((source: OriginType) => [
            source,
            new ManufacturerPointsService({
              route: 'stream.manufacturerPoints',
              connectionType: StreamRequestType.MotorsportsStreamApi,
              originType: source,
              sanctioningBody: sanctioningBody,
            }),
          ])
      ),
    ])
);
